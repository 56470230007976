import type {I18nRequestContext} from '@/types';
import {helpUrl, blogPath} from '@/hooks/navigation/utils';
import {localizePathBySite} from '@/utils/site';

import {MAIN_NAV_GA_EVENT} from '../constants';

export function getHelpNav({t, site}: I18nRequestContext) {
  const baseEnSites = [
    'en.shopify.dk',
    'en.shopify.hk',
    'en.shopify.be',
    'en.shopify.no',
    'www.shopify.ca',
    'www.shopify.co.id',
    'www.shopify.co.nz',
    'www.shopify.co.uk',
    'www.shopify.co.za',
    'www.shopify.com',
    'www.shopify.com.au',
    'www.shopify.com.ng',
    'www.shopify.com.ph',
    'www.shopify.com.sg',
    'www.shopify.ie',
    'www.shopify.in',
    'www.shopify.my',
  ];

  const navItems = [
    {
      content: t('nav:helpAndSupport'),
      url: helpUrl(site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Help and support',
      },
      description: t('nav:helpAndSupportDescription'),
    },
  ];

  if (baseEnSites.includes(site?.domain)) {
    navItems.push({
      content: t('nav:howToGuides'),
      url: blogPath('/topics/guides', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'How-to guides',
      },
      description: t('nav:howToGuidesDescription'),
    });
  }

  const businessCoursesAllowListLocales: string[] = [
    'en',
    'en-BE',
    'en-DK',
    'en-NO',
    'en-ID',
    'en-PH',
    'en-ZA',
    'en-MY',
    'en-NG',
    'en-HK',
  ];

  if (businessCoursesAllowListLocales.includes(site.locale)) {
    navItems.push({
      content: t('nav:compass'),
      url: 'https://academy.shopify.com',
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Business courses',
      },
      description: t('nav:compassDescription'),
    });
  }

  if (site.features.includes('blog')) {
    navItems.push({
      content: t('nav:shopifyBlog'),
      url: blogPath('/', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Shopify blog',
      },
      description: t('nav:shopifyBlogDescription'),
    });
  }

  if (site.locale === 'ja') {
    navItems.push({
      content: t('nav:shopifyComparison'),
      url: localizePathBySite('/compare/shopify-vs-others', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Shopify comparison',
      },
      description: t('nav:shopifyComparisonDescription'),
    });
    navItems.push({
      content: t('nav:shopifyMigration'),
      url: localizePathBySite('/migrate', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Migrate to Shopify',
      },
      description: t('nav:shopifyMigrationDescription'),
    });
  }

  return {
    content: t('nav:helpAndSupport'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Help and support Nav',
    },
    columnStart: 1,
    columnSpan: 3,
    icon: 'helpAndSupport',
    navItems,
  };
}
